import { type CSSProperties, type FC, useEffect, useState } from "react";

import styled from "@emotion/styled";
import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { palette } from "./Palette";

const useLoadedImage = (src: string | null | undefined) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!src) return;
    setIsLoaded(false);

    const img = new Image();
    img.onload = () => setIsLoaded(true);
    img.onerror = () => {
      setIsLoaded(true);
      setIsError(true);
    };
    img.src = src;
  }, [src]);

  return { isLoaded, isError };
};

const SAvatar = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
  border: 2px solid white;
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.2));
`;

export const Avatar: FC<{
  src: string | null | undefined;
  placeholderSize?: SizeProp;
  size: number;
  style?: CSSProperties;
  alt?: string | null;
  className?: string;
}> = ({ src, placeholderSize, size, style, alt, className }) => {
  const { isLoaded, isError } = useLoadedImage(src);
  if (!src || !isLoaded || isError) {
    const iconScale = 0.65;
    return (
      <FontAwesomeIcon
        className={className}
        style={{
          width: size * iconScale,
          height: size * iconScale,
          padding: (size * (1 - iconScale)) / 2
        }}
        size={placeholderSize}
        color={palette.gray[50]}
        icon={faUserTie}
      />
    );
  }
  return (
    <SAvatar
      className={className}
      style={{ width: size, height: size, ...style }}
      alt={alt || "avatar"}
      src={src}
    />
  );
};
