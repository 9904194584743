import styled from "@emotion/styled";
import { Fab, Tooltip } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon, type IconProp } from "@relatable/ui/icons";

interface StateChangeFabProps {
  className?: string;
  noBackground?: boolean;
  component?: React.ReactNode;
  disabled?: boolean;
  handleOnClick: (any) => void;
  icon: IconProp;
  title: string;
}
export const StateChangeFab: React.FC<StateChangeFabProps> = props => {
  const {
    className = "",
    noBackground,
    title,
    handleOnClick,
    icon,
    disabled = false,
    component = "button"
  } = props;
  return (
    <Tooltip title={title} aria-label={title.toLowerCase()} placement="top">
      <StyledFab
        component={component}
        disabled={disabled}
        className={`state-change-fab ${noBackground ? "no-background" : ""} ${className}`}
        size="small"
        {...(title && disabled && { component: "div" })}
        {...(!disabled && { onClick: handleOnClick })}
      >
        <FontAwesomeIcon icon={icon} />
      </StyledFab>
    </Tooltip>
  );
};

const StyledFab = styled(Fab)`
  && {
    width: 32px;
    height: 32px;
    min-height: 32px;
    font-size: 20px;
    box-shadow: none;
    color: ${palette.gray[60]};
    background-color: ${palette.gray.whiteOff};

    &.approve {
      background-color: ${palette.gray.black};
      color: ${palette.gray.white};
      &:hover {
        background-color: ${palette.gray[80]};
      }
    }

    &.Mui-disabled {
      pointer-events: auto;
      background-color: ${palette.gray.whiteOff};

      &:hover {
        background-color: ${palette.gray.whiteOff};
      }
    }

    &.no-background {
      background: none;
      color: ${palette.gray[40]};
      &:hover {
        background-color: none !important;
      }
    }
  }
`;
