import type { FC } from "react";

import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faArrowLeft, faArrowRight, faTimes } from "@relatable/ui/icons/pro-light-svg-icons";
import { Link, useParams } from "react-router-dom";

import type { ContentDetailsQuery } from "../generated";
import { GalleryItem } from "./GalleryItem";

export const GalleryMediaId: FC<{
  medias: ContentDetailsQuery["contents"][0]["campaign_preapproval_content_medias"];
  caption: ContentDetailsQuery["contents"][0]["content_preapproval_caption"];
}> = ({ medias, caption }) => {
  const { mediaId } = useParams<{ mediaId: string }>();

  const isCaption = mediaId === "caption";

  const selectedMediaIndex =
    !isCaption && mediaId ? medias.findIndex(media => media.id === Number(mediaId)) : 0;

  const prevLink = (() => {
    if (isCaption && medias.length > 0) {
      return `../${medias[medias.length - 1].id}`;
    }
    if (mediaId && medias[selectedMediaIndex - 1]) {
      return `../${medias[selectedMediaIndex - 1].id}`;
    }
    return null;
  })();

  const nextLink = (() => {
    if (isCaption) {
      return null;
    }
    if (medias[selectedMediaIndex + 1]) {
      return `../${medias[selectedMediaIndex + 1].id}`;
    }
    if (caption) {
      return "../caption";
    }
    return null;
  })();

  return (
    <Root className="open">
      <Link to=".." className="close">
        <FontAwesomeIcon icon={faTimes} />
      </Link>
      <Link to={prevLink ?? {}} style={{ visibility: prevLink ? "visible" : "hidden" }}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Link>
      {isCaption ? (
        <GalleryItem mediaId={mediaId} caption={caption} />
      ) : (
        <GalleryItem
          mediaId={mediaId}
          media={medias[selectedMediaIndex]}
          index={selectedMediaIndex}
          numItems={medias.length}
        />
      )}
      <Link to={nextLink ?? {}} style={{ visibility: nextLink ? "visible" : "hidden" }}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  position: relative;
  flex-shrink: 0;

  &.open {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > a {
      color: ${palette.gray[60]};
    }

    > .close {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;
