import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faArrowRight } from "@relatable/ui/icons/pro-solid-svg-icons";

import { InfoCard } from "components/ui/InfoCard";

interface NoCreatorsStateProps {
  setFilterToAll: () => void;
}

export const NoCreatorsState = ({ setFilterToAll }: NoCreatorsStateProps): JSX.Element => {
  return (
    <Root>
      <InfoCard>
        <div className="title">
          <h1>No results found</h1>
          <Button variant="outlined" onClick={setFilterToAll}>
            View All Creators
            <FontAwesomeIcon style={{ marginLeft: 4 }} icon={faArrowRight} />
          </Button>
        </div>
        <p>Expand your filter to view more Creators</p>
      </InfoCard>
    </Root>
  );
};

const Root = styled.div`
  margin-top: 72px;

  > .MuiCard-root {
    > .title {
      display: flex;
      justify-content: space-between;

      > h1 {
        font-size: 20px;
      }

      .icon {
        margin-left: 4px;
      }
    }
    > p {
      margin-bottom: 0;
    }
  }
`;
