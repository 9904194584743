import styled from "@emotion/styled";
import { faCog } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon, type FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { prettifyNumber } from "@relatable/helpers";
import { palette } from "@relatable/ui/Palette";
import type { CSSProperties } from "react";

export const Loader: React.FC<{
  message?: string;
  size?: FontAwesomeIconProps["size"];
  hide?: boolean;
  progress?: number | null;
  hideMessage?: boolean;
  rootClassName?: string;
  style?: CSSProperties;
}> = ({
  message = "Loading...",
  size = "4x",
  hide = false,
  progress,
  hideMessage = false,
  style,
  rootClassName = ""
}) => {
  if (hide) return null;

  return (
    <Root style={style} className={rootClassName}>
      <FontAwesomeIcon color={palette.primary.red} icon={faCog} size={size} spin />
      {typeof progress === "number" ? (
        <Message>{prettifyNumber(progress, { percentages: true })}</Message>
      ) : null}
      {hideMessage ? null : <Message>{message}</Message>}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`;

const Message = styled.span`
  max-width: 200px;
  word-wrap: break-word;
  text-align: center;
`;
