import type { FC } from "react";

import type { Platform_Enum } from "@relatable/gql/generated-base";
import { FontAwesomeIcon, type FontAwesomeIconProps } from "@relatable/ui/icons";
import {
  faInstagram,
  faSnapchat,
  faTiktok,
  faYoutube
} from "@relatable/ui/icons/free-brands-svg-icons";

type PlatformIconProps = {
  platform: Platform_Enum | null | undefined;
  size?: FontAwesomeIconProps["size"];
  className?: string;
};

export const PlatformIcon: FC<PlatformIconProps> = ({ size, platform, className = "" }) => {
  const iconProps = (() => {
    if (platform === "instagram") return { icon: faInstagram };
    if (platform === "youtube") return { icon: faYoutube };
    if (platform === "tiktok") return { icon: faTiktok };
    if (platform === "snapchat") return { icon: faSnapchat };
    throw new Error(`${platform} is not a valid one`);
  })();

  return <FontAwesomeIcon className={className} {...iconProps} {...(size && { size })} />;
};
