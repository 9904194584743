import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon, type FontAwesomeIconProps } from "@relatable/ui/icons";

interface StatusChipProps {
  label: string;
  icon?: FontAwesomeIconProps["icon"];
  backgroundColor?: string;
  color?: string;
}

export const StatusChip: React.FunctionComponent<StatusChipProps> = ({
  label,
  backgroundColor,
  icon,
  color
}) => {
  return (
    <StyledChip
      label={
        <>
          {icon ? <StyledIcon icon={icon} /> : null}
          {label}
        </>
      }
      style={{
        backgroundColor: backgroundColor || palette.gray[70],
        color: color ?? "#fff"
      }}
    />
  );
};

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 13px;
  margin-right: 8px;
  vertical-align: sub;
`;

const StyledChip = styled(Chip)`
  && {
    cursor: inherit;
    height: 32px;
    font-size: inherit;

    > .MuiChip-label {
      display: inline-flex;
      align-items: center;
    }
  }
`;
