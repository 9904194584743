import styled from "@emotion/styled";
import { Box, MenuItem, Select, Tooltip } from "@mui/material";
import type { Campaign_User_State_Enum } from "@relatable/gql/generated-base";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faCheck, faCircle, faTimes } from "@relatable/ui/icons/pro-light-svg-icons";
import { faCheckCircle } from "@relatable/ui/icons/pro-solid-svg-icons";

import { StatusChip } from "components/ui/StatusChip";

import { NO_COMMENT_ON_REJECTION_MESSAGE } from "./UserRow";

interface StatusFieldProps {
  hasClientComment: boolean;
  userState: Campaign_User_State_Enum;
  setUserState: (state: Campaign_User_State_Enum) => void;
  isStateEditable: boolean;
}

export const StatusField: React.FunctionComponent<StatusFieldProps> = props => {
  const { hasClientComment, userState, setUserState, isStateEditable } = props;

  const renderStateDropdownItem = (state: Campaign_User_State_Enum, showCheckbox = true) => {
    const isDisabled = state === "client_declined" && !hasClientComment;

    const backgroundColor = (() => {
      if (state === "client_reviewing") return palette.primary.red;
      if (["client_approved", "approved"].includes(state)) return palette.primary.green;
      if (["client_declined", "rejected"].includes(state)) return palette.primary.gold;
      return undefined;
    })();

    const icon = (() => {
      if (["client_approved", "approved"].includes(state)) return faCheck;
      if (["client_declined", "rejected"].includes(state)) return faTimes;
      return undefined;
    })();

    const label = (() => {
      if (state === "client_reviewing") return "Needs Review";
      if (state === "client_approved") return "Approved";
      if (state === "client_declined") return "Declined";
      if (state === "approved") return "Approved";
      if (state === "rejected") return "Not approved";
      return "Unknown";
    })();

    const tooltipTitle = (() => {
      if (isDisabled) return NO_COMMENT_ON_REJECTION_MESSAGE;
      if (!isStateEditable) {
        // eslint-disable-next-line max-len
        return "We're in communication with creator already. if you have a change of heart, please reach out to PM directly";
      }
      return "";
    })();

    return (
      <StyledMenuItem disabled={isDisabled} dense disableGutters value={state} key={state}>
        {showCheckbox ? (
          <StyledCheckboxIcon icon={state === userState ? faCheckCircle : faCircle} />
        ) : null}
        <Tooltip title={tooltipTitle}>
          <Box mr={showCheckbox ? 2 : 0}>
            {state !== null ? (
              <StatusChip
                label={label}
                icon={icon}
                backgroundColor={backgroundColor}
                color={palette.gray.white}
              />
            ) : null}
          </Box>
        </Tooltip>
      </StyledMenuItem>
    );
  };

  return (
    <StyledSelect
      disableUnderline
      value={userState}
      renderValue={() => renderStateDropdownItem(userState, false)}
      onChange={e =>
        e.target.value === "client_declined" && !hasClientComment
          ? null
          : setUserState(e.target.value as Campaign_User_State_Enum)
      }
      {...(!isStateEditable && { $isStateEditable: isStateEditable, disabled: true })}
    >
      {isStateEditable
        ? [
            renderStateDropdownItem("client_reviewing"),
            renderStateDropdownItem("client_approved"),
            renderStateDropdownItem("client_declined")
          ]
        : [renderStateDropdownItem("approved"), renderStateDropdownItem("rejected")]}
    </StyledSelect>
  );
};

const StyledCheckboxIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin: 0 8px 0 16px;
`;

const StyledSelect = styled(Select)<{ $isStateEditable?: boolean }>`
  box-shadow: none;
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  && {
    border: none;
    outline: none;
    box-shadow: none;

    * > {
      border: none;
      outline: none;
      box-shadow: none;
    }
    opacity: ${({ $isStateEditable = true }) => ($isStateEditable ? "initial" : 0.5)};
    height: 32px;

    > .MuiSelect-select {
      background: inherit;
      padding: 0;

      > .MuiMenuItem-root {
        padding: 0;
      }
    }

    > .MuiSelect-icon {
      display: none;
    }

    &:before,
    &:after {
      content: normal; /* remove default underline */
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  border: none;

  && {
    color: ${palette.gray.white};
    &:hover {
      background: inherit;
    }
    &.Mui-selected {
      background: ${palette.gray[20]};
    }

    &.Mui-disabled {
      && {
        pointer-events: auto;
      }
    }
  }
`;
