import { type FC, useCallback, useState } from "react";

import styled from "@emotion/styled";
import {
  faFaceAngry,
  faFaceFrownSlight,
  faFaceGrinStars,
  faFaceMeh,
  faFaceSmile
} from "@fortawesome/pro-regular-svg-icons";
import { CLIENT_REVIEW_FIELDS } from "@relatable/helpers/constants";
import { Button } from "@relatable/ui/Button";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { theme } from "@relatable/ui/Theme";

import { Container } from "components/Layout";
import type { ClientsOverviewQuery } from "hooks/generated";
import { ScoreStep } from "modules/overview/review/Step";
import {
  ClientReviewDocument,
  useClientReviewQuery,
  useInsertClientReviewMutation,
  useSubmitClientReviewMutation,
  useUpdateClientReviewMutation
} from "modules/overview/review/generated";

const icons = [faFaceAngry, faFaceFrownSlight, faFaceMeh, faFaceSmile, faFaceGrinStars];

export const ProjectReview: FC<{
  project: ClientsOverviewQuery["clients"][number]["projects"][number];
}> = ({ project }) => {
  const [step, setStep] = useState(0);

  const currentStep = CLIENT_REVIEW_FIELDS[step];

  const { data } = useClientReviewQuery({
    variables: { projectId: project.id }
  });
  const [update] = useUpdateClientReviewMutation({
    refetchQueries: [ClientReviewDocument]
  });
  const [submit] = useSubmitClientReviewMutation({
    refetchQueries: [ClientReviewDocument],
    awaitRefetchQueries: true
  });
  const [create] = useInsertClientReviewMutation({
    refetchQueries: [ClientReviewDocument]
  });

  const clientReview = data?.project_client_review?.[0];
  const [isDone, setIsDone] = useState(clientReview?.is_submitted || false);

  const handleDone = () => {
    setIsDone(true);
    return submit({
      variables: {
        projectId: project.id
      }
    });
  };

  const handleChange = useCallback(
    (v: string | number | null) => {
      if (clientReview?.id) {
        update({
          variables: {
            projectId: project.id,
            set: {
              [currentStep.id]: v
            }
          }
        });
      } else {
        create({
          variables: {
            object: {
              project_id: project.id,
              [currentStep.id]: v
            }
          }
        });
      }
    },
    [clientReview?.id, currentStep.id, project.id, update, create]
  );

  if (isDone) {
    return (
      <Container style={{ maxWidth: 700, marginRight: "auto" }}>
        <h2>Relatable feedback form</h2>
        <p style={{ color: theme.palette.grey[600], height: 50 }}>
          Thank you for taking the time to provide us with your feedback. We truly value your
          partnership and look forward to continuing to work with you in the future.
        </p>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onAsyncClick={() => {
              setStep(0);
              setIsDone(false);
              return update({
                variables: {
                  projectId: project.id,
                  set: {
                    is_submitted: false
                  }
                }
              });
            }}
          >
            Re review
          </Button>

          <Button href={`/overview/p/${project.stub}`}>Go back</Button>
        </div>
      </Container>
    );
  }

  return (
    <Container style={{ maxWidth: 700, marginRight: "auto" }}>
      <h2>Relatable Influencer Marketing Campaign Feedback Form</h2>
      {step === 0 && (
        <p style={{ color: theme.palette.grey[600] }}>
          Thank you for selecting Relatable to assist with your influencer marketing campaign. We
          highly value your feedback and kindly request that you take 5 minutes to complete this
          form. Your response will be important in helping us enhance our services and deliver even
          better results in the future.
        </p>
      )}

      <h3 style={{ marginTop: 10 }}>{currentStep.title}</h3>
      <p style={{ color: theme.palette.grey[600], height: 50 }}>{currentStep.desc}</p>
      {data ? (
        <>
          <ScoreStep
            key={currentStep.id}
            value={clientReview?.[currentStep.id]}
            textInput={Boolean(currentStep.textInput)}
            onChange={handleChange}
            steps={currentStep.answers.map((a, index) => ({
              icon: currentStep.answers.length === 3 ? icons[index + 1] : icons[index],
              value: index + 1,
              title: a
            }))}
          />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
              disabled={step < 1}
              style={{ minHeight: 35, marginTop: "auto" }}
              onClick={() => setStep(step - 1)}
              variant="contained"
              color="secondary"
            >
              Previous
            </Button>

            <Dots>
              {new Array(CLIENT_REVIEW_FIELDS.length).fill(0).map((_, i) => (
                <button
                  type="button"
                  key={i}
                  className={`dot ${i === step ? "active" : ""}`}
                  onClick={() => setStep(i)}
                >
                  {i}
                </button>
              ))}
            </Dots>

            <Button
              variant="contained"
              color="primary"
              disabled={step + 1 > CLIENT_REVIEW_FIELDS.length}
              style={{ minHeight: 35, marginTop: "auto" }}
              onAsyncClick={async () =>
                step + 1 === CLIENT_REVIEW_FIELDS.length ? handleDone() : setStep(p => p + 1)
              }
            >
              {step + 1 === CLIENT_REVIEW_FIELDS.length ? "Done" : "Next"}
            </Button>
          </div>
        </>
      ) : (
        <Loader style={{ position: "relative", margin: "50px auto" }} />
      )}
    </Container>
  );
};

const Dots = styled.div`
  display: flex;

  > .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${palette.gray[60]};
    margin-right: 10px;
    font-size: 0;
    border: 0;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.active {
      background-color: ${palette.primary.red};
      width: 32px;
    }
  }
`;
