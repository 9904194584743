import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faCheckCircle } from "@relatable/ui/icons/pro-solid-svg-icons";

interface QuotaIndicatorProps {
  total: number;
  min: number;
  current: number;
  variant: "large" | "small";
}

export const QuotaIndicator: React.FC<QuotaIndicatorProps> = ({ total, min, current, variant }) => {
  const minMarkerPercentage = Math.max(10, Math.min(90, (min / total) * 100));
  const minMarkerLeft = `${minMarkerPercentage.toFixed(2)}%`;

  const activePercentage = Math.max(7, Math.min(100, (current / total) * 100 + 2));
  const activeWidth = `${activePercentage.toFixed(2)}%`;

  const isComplete = current >= min;

  const infoText = (() => {
    if (current === 0) return `Approve at least ${min} ${min !== 1 ? "Creators" : "Creator"}`;
    if (current >= 1 && current < min) {
      return `Approve at least ${min - current} more ${
        min - current !== 1 ? "Creators" : "Creator"
      }`;
    }
    if (current >= min) return "All good. Approve more for a better result";
    return null;
  })();

  return (
    <Root $activeWidth={activeWidth} $markerLeft={minMarkerLeft} $variant={variant}>
      <span className="text">{infoText}</span>
      <span className="line">
        <span className={`active ${isComplete ? "complete" : "incomplete"}`} />
        <span className={`marker ${isComplete ? "complete" : "incomplete"}`}>
          {isComplete ? (
            <FontAwesomeIcon className="item check" icon={faCheckCircle} />
          ) : (
            variant === "large" && <span className="item min-count">{min}</span>
          )}
        </span>
      </span>
    </Root>
  );
};

interface QuotaIndicatorRootProps {
  $activeWidth: string;
  $markerLeft: string;
  $variant: "large" | "small";
}

const Root = styled.div<QuotaIndicatorRootProps>`
  display: ${({ $variant }) => ($variant === "small" ? "inline-flex" : "block")};
  flex-direction: column;
  min-width: ${({ $variant }) => ($variant === "small" ? "260px" : "initial")};

  > .text {
    display: block;
    font-size: 13px;
    font-weight: ${({ $variant }) => ($variant === "small" ? "normal" : "bold")};
    margin-bottom: ${({ $variant }) => ($variant === "small" ? "4px" : "20px")};
  }

  > .line {
    margin-top: 8px;
    position: relative;
    display: block;
    height: ${({ $variant }) => ($variant === "small" ? "12px" : "16px")};
    background-image: linear-gradient(
      135deg,
      ${palette.gray.whiteOff} 25%,
      ${palette.gray[10]} 25%,
      ${palette.gray[10]} 50%,
      ${palette.gray.whiteOff} 50%,
      ${palette.gray.whiteOff} 75%,
      ${palette.gray[10]} 75%,
      ${palette.gray[10]} 100%
    );
    background-size: 20px 20px;
    border-radius: 12px;

    > .marker {
      position: absolute;
      top: 0;
      left: ${p => p.$markerLeft};
      background: ${palette.gray[60]};
      width: 2px;
      height: 100%;

      &:before {
        content: "";
        width: 0;
        height: 0;
        position: relative;
        bottom: 1px;
        left: -4px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${palette.gray[60]};
      }

      > .item {
        position: absolute;
        left: 0;
        color: ${palette.gray.black};

        &.check {
          top: -8px;
          font-size: 8px;
          margin-left: -3px;
        }

        &.min-count {
          top: ${({ $variant }) => ($variant === "small" ? -20 : -28)}px;
          font-size: 10px;
          margin-left: -4px;
        }
      }

      &.complete {
        background: ${palette.gray.black};
        &:before {
          display: none;
        }
      }
    }

    > .active {
      background: ${palette.secondary.gold};
      position: absolute;
      top: 0;
      width: ${p => p.$activeWidth};
      bottom: 0;
      border-radius: 12px;
      border: 1px solid ${palette.gray.white};
      z-index: 0;

      &.complete {
        background: ${palette.primary.gold};
      }
    }
  }
`;
