import type { FC } from "react";

import styled from "@emotion/styled";
import { LinearProgress } from "@mui/material";
import { clientReviewed, normalizeState } from "@relatable/helpers/approvalLogs";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faCheck } from "@relatable/ui/icons/pro-light-svg-icons";

import type { CampaignsOverviewQuery } from "../generated";

export const Footer: FC<{
  campaignUsers: CampaignsOverviewQuery["campaigns"][0]["campaign_users"];
  onSubmitReview: () => void;
}> = ({ campaignUsers, onSubmitReview }) => {
  let allItems = 0;
  let finishedItems = 0;

  campaignUsers.forEach(cu => {
    cu.campaign_preapproval_contents.forEach(cpc => {
      cpc.campaign_preapproval_content_medias.forEach(media => {
        const state = normalizeState(media.content_preapproval_logs[0]?.state);
        if (clientReviewed(state)) finishedItems++;
        allItems++;
      });
      if (cpc.content_preapproval_caption) {
        const state = normalizeState(
          cpc.content_preapproval_caption.content_preapproval_logs[0]?.state
        );
        if (clientReviewed(state)) finishedItems++;
        allItems++;
      }
    });
  });

  if (allItems === 0) return null;

  return (
    <Root>
      <div className="content">
        <div className="left">
          <span className="line">
            <LinearProgress
              color="inherit"
              variant="determinate"
              value={Math.round((finishedItems / allItems) * 100)}
              style={{ height: 8 }}
            />
          </span>
          <span className="label">
            {finishedItems} of {allItems} items are reviewed
          </span>
        </div>
        <div className="right">
          <Button
            size="medium"
            style={{ marginLeft: 4 }}
            onClick={() => onSubmitReview()}
            icon={<FontAwesomeIcon icon={faCheck} size="sm" style={{ marginRight: 4 }} />}
          >
            Submit review
          </Button>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${palette.gray.white};
  border-top: 1px solid ${palette.gray[20]};

  > .content {
    max-width: 1600px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 36px;

    > .left {
      display: flex;
      align-items: center;
      > .line {
        width: 250px;
        margin-right: 15px;
        color: ${palette.primary.red};
      }
    }

    > .right {
      display: flex;
    }
  }
`;
