import { useState } from "react";

import styled from "@emotion/styled";
import { Box, Dialog } from "@mui/material";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import { faTimes } from "@relatable/ui/icons/pro-light-svg-icons";

import { InfoCard } from "components/ui/InfoCard";
import { Textfield } from "components/ui/Textfield";

import { QuotaIndicator, SubmitReviewButton } from "../common";

interface LowQuotaProps {
  current: number;
  listNeedsSubmit: boolean;
  min: number;
  onDismiss: () => void;
  onForceSubmit: (text: string) => void;
  open: boolean;
  submitClientListLoading: boolean;
  total: number;
}

export const LowQuota: React.FunctionComponent<LowQuotaProps> = ({
  current,
  min,
  onDismiss,
  onForceSubmit,
  open,
  submitClientListLoading,
  total,
  listNeedsSubmit
}) => {
  const [feedback, setFeedback] = useState("");

  return (
    <Root open={open} onClose={onDismiss}>
      <StyledCloseIcon size="lg" icon={faTimes} onClick={onDismiss} />
      <InfoCard>
        <h1>You&apos;ve selected a low amount of Creators</h1>
        <p>
          We highly recommend choosing a higher volume of selection. This gives us wiggle room when
          booking Creators. Please revise your selection and approve a few more.
        </p>
        <Box my={3}>
          <QuotaIndicator min={min} current={current} total={total} variant="large" />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="outlined" onClick={onDismiss}>
            Revise Selection
          </Button>
        </Box>
        <div className="spacer" />
        <h2>Are you sure your selection is solid?</h2>
        <p>Let us know your thoughts & submit the list. We`ll reach out with next steps.</p>
        <Textfield
          value={feedback}
          className="feedback"
          onChange={e => setFeedback(e.target.value)}
          multiline
          placeholder="Add comment"
          rows={4}
        />
        <Box display="flex" justifyContent="flex-end">
          <SubmitReviewButton
            isSaving={submitClientListLoading}
            listNeedsSubmit={listNeedsSubmit}
            onSubmit={() => {
              onForceSubmit(feedback);
              setTimeout(onDismiss, 1200);
            }}
          />
        </Box>
      </InfoCard>
    </Root>
  );
};

const Root = styled(Dialog)`
  .MuiCard-root {
    > h1 {
      font-size: 20px;
    }

    > h2 {
      font-size: 18px;
    }

    > .spacer {
      height: 1px;
      background: ${palette.gray[40]};
      margin: 24px -48px;
    }

    > .feedback > .feedback {
      margin-bottom: 8px;
      padding: 8px 16px;
    }
  }
`;

const StyledCloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 32px;
  right: 48px;
  z-index: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;
