import { type FC, type MouseEvent, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { Loader } from "@relatable/ui/Loader";

interface ContentDisplayProps {
  url?: string;
  thumbnail?: string;
  isVideo?: boolean;
  loading?: boolean;
  open?: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const ContentDisplay: FC<ContentDisplayProps> = ({
  url,
  thumbnail = "",
  isVideo,
  loading,
  open,
  onClick
}) => {
  const [contentLoaded, setContentLoaded] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (loading) {
      setContentLoaded(false);
    }
  }, [loading]);

  const onLoad = () => {
    setContentLoaded(true);
  };

  const onVideoClick = e => {
    if (!video.current) return;

    if (onClick) {
      onClick(e);
      return;
    }

    if (!videoPlaying) {
      video.current.play();
    } else {
      video.current.pause();
    }

    setVideoPlaying(prev => !prev);
  };

  const onVideoError = e => {
    console.error(e?.target?.error);
    setVideoError(true);
    setContentLoaded(true);
  };

  const onThumbnailError = () => {
    setContentLoaded(true);
  };

  if (!url) {
    return <NoImagePlaceholder>No content uploaded..</NoImagePlaceholder>;
  }

  if (!thumbnail) {
    return <NoImagePlaceholder>Content not found..</NoImagePlaceholder>;
  }

  if (videoError) {
    return (
      <FallbackText>
        Your browser does not support this format. Please <strong>right click</strong>{" "}
        <a href={url} download>
          here
        </a>{" "}
        and select <strong>save link as</strong> to download the content and view it on your
        computer.
      </FallbackText>
    );
  }

  return (
    <>
      <NoImagePlaceholder $show={!contentLoaded}>
        <Loader size="2x" message="The content is loading.." />
      </NoImagePlaceholder>
      <Frame $show={!loading && contentLoaded && !videoError}>
        {isVideo ? (
          <MediaVideo
            $open={open}
            ref={video}
            src={url}
            onLoadedData={onLoad}
            onClick={onVideoClick}
            onError={onVideoError}
            controls
          >
            <source src={url} type="video/mp4" />
            <source src={url} type="video/ogg" />
            Your browser does not support the video tag.
          </MediaVideo>
        ) : (
          <ImageLink target="_blank" rel="noreferer" onClick={onClick}>
            <MediaImage $open={open} onLoad={onLoad} src={thumbnail} onError={onThumbnailError} />
          </ImageLink>
        )}
      </Frame>
    </>
  );
};

const NoImagePlaceholder = styled.div<{ $show?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: ${({ $show = true }) => ($show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 200;
`;

const Frame = styled.div<{ $show?: boolean }>`
  display: ${({ $show }) => ($show ? "block" : "none")};
  position: relative;
  height: 100%;
`;

const ImageLink = styled.a`
  position: relative;

  &:hover {
    opacity: 0.95;
  }
`;

const MediaImage = styled.img<{ $open?: boolean }>`
  height: 100%;
  max-height: ${({ $open }) => ($open ? "492px" : "392px")};
  object-fit: contain;
`;

const MediaVideo = styled.video<{ $open?: boolean }>`
  max-height: ${({ $open }) => ($open ? "492px" : "392px")};
  object-fit: contain;
  max-width: 100%;
  height: 100%;
`;

const FallbackText = styled.span`
  text-align: center;
`;
