import { type FC, useCallback, useMemo, useState } from "react";

import styled from "@emotion/styled";
import { debounce } from "@mui/material";
import type {
  Campaign_User_Set_Input,
  Campaign_User_State_Enum
} from "@relatable/gql/generated-base";
import { addCommasIfNumber, numberWithCommas } from "@relatable/helpers";
import { Avatar } from "@relatable/ui/Avatar";
import { palette } from "@relatable/ui/Palette";
import { TextInput } from "@relatable/ui/TextInput";
import { FontAwesomeIcon } from "@relatable/ui/icons";
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faTimes
} from "@relatable/ui/icons/pro-light-svg-icons";
import { Link } from "react-router-dom";

import { PlatformIcon } from "components/ui/PlatformIcon";

// eslint-disable-next-line no-restricted-imports
import type { CreatorDataRow } from "../../utils/creator_transform";
import { StateChangeFab } from "../StateChangeFab";
import { StatusField } from "../StatusField";
import { NO_COMMENT_ON_REJECTION_MESSAGE } from "../UserRow";
import { ThumbnailContainer } from "./common/ThumbnailContainer";

export const CreatorSidebar: FC<{
  row: CreatorDataRow;
  extraFields: { key: string; label: string; isNumberCell: boolean }[];
  prevCampaignUserId: number | null;
  nextCampaignUserId: number | null;
  setSidebarCampaignUserId: (campaignUserId: number) => void;
  onCloseSidebar: () => void;
  updateCampaignUser: (campaignUserId: number, data: Campaign_User_Set_Input) => void;
  setCampaignUserState: (campaignUserId: number, state: Campaign_User_State_Enum) => void;
}> = ({
  onCloseSidebar,
  extraFields,
  nextCampaignUserId,
  prevCampaignUserId,
  row,
  setCampaignUserState,
  setSidebarCampaignUserId,
  updateCampaignUser
}) => {
  const { platformName, platform, campaignUserId } = row;
  const [comment, setComment] = useState(row.clientComment);

  const setUser = useCallback(
    (data: Campaign_User_Set_Input) => {
      if (campaignUserId) {
        updateCampaignUser(campaignUserId, data);
      }
    },
    [updateCampaignUser, campaignUserId]
  );

  const debouncedSetUser = useMemo(() => debounce(setUser, 200), [setUser]);

  const updateCreatorState = useCallback(
    state => {
      if (nextCampaignUserId !== null) {
        setSidebarCampaignUserId(nextCampaignUserId);
      }
      if (campaignUserId) {
        setCampaignUserState(campaignUserId, state);
      }
    },
    [setSidebarCampaignUserId, nextCampaignUserId, setCampaignUserState, campaignUserId]
  );

  const hasClientComment = (row.clientComment ?? "") !== "";

  const renderUserInfo = () => {
    return (
      <UserInfoContainer>
        <Avatar src={row.profilePicture} className="avatar" size={40} />
        <div className="wrapper">
          <span>
            <strong className="platform-name">
              {platform === "instagram" ? "@" : ""}
              {platformName}
            </strong>
            <Link to={row.url} className="platform-icon" target="_blank" rel="noreferrer">
              <PlatformIcon platform={platform} size="sm" />
            </Link>
          </span>
          <span className="stats">
            {numberWithCommas(row.platformFollowersCount || 0)} followers
          </span>
        </div>
      </UserInfoContainer>
    );
  };

  const renderMetricsRow = ({ key, label }: (typeof extraFields)[0]) => {
    const content = row.clientData?.[key] || undefined;
    const formattedContent = addCommasIfNumber(content?.value ?? "");

    return (
      <ClientDataRow key={key}>
        <span className="label">{label}</span>
        <span className="data" {...(content?.link && { onClick: e => e.stopPropagation() })}>
          {content?.link ? (
            <Link to={content.link} target="_blank" rel="noreferrer">
              {formattedContent}
            </Link>
          ) : (
            formattedContent || "-"
          )}
        </span>
      </ClientDataRow>
    );
  };

  if (!campaignUserId) return null;

  return (
    <Root>
      <HeaderWrapper>
        <Header>
          {renderUserInfo()}
          <div className="icons">
            <SidebarIcon
              className={prevCampaignUserId ? "" : "disabled"}
              icon={faArrowLeft}
              size="lg"
              onClick={() =>
                prevCampaignUserId ? setSidebarCampaignUserId(prevCampaignUserId) : null
              }
              role="button"
              title="Previous"
            />
            <SidebarIcon
              className={nextCampaignUserId ? "" : "disabled"}
              icon={faArrowRight}
              size="lg"
              onClick={() =>
                nextCampaignUserId ? setSidebarCampaignUserId(nextCampaignUserId) : null
              }
              role="button"
              title="Next"
            />
            <SidebarIcon
              icon={faTimes}
              size="lg"
              onClick={onCloseSidebar}
              role="button"
              title="Close"
            />
          </div>
        </Header>
      </HeaderWrapper>
      <InfoContainer>
        <ThumbnailContainer row={row} />

        {extraFields.length > 0 ? (
          <MetricsContainer>
            <h4 style={{ fontWeight: 700, padding: "10px 15px" }}>Overall metrics</h4>
            {extraFields.map(renderMetricsRow)}
          </MetricsContainer>
        ) : null}
      </InfoContainer>
      <FooterWrapper>
        <Footer>
          <TextInput
            size="small"
            style={{ width: "100%" }}
            label="Approve creator"
            placeholder="Add comment"
            value={comment}
            onChange={v => {
              setComment(v);
              debouncedSetUser({ client_comment: v });
            }}
            {...(row.isStateEditable ? {} : { disabled: true })}
          />
          <ActionbarContainer $showActionButtons={row.state === "client_reviewing"}>
            <StateChangeFab
              className="approve"
              title="Approve"
              icon={faCheck}
              handleOnClick={() => updateCreatorState("client_approved")}
            />

            <StatusField
              hasClientComment={hasClientComment}
              setUserState={state => setCampaignUserState(campaignUserId, state)}
              userState={row.state}
              isStateEditable={row.isStateEditable}
            />

            <StateChangeFab
              disabled={!hasClientComment}
              title={hasClientComment ? "Decline" : NO_COMMENT_ON_REJECTION_MESSAGE}
              icon={faTimes}
              handleOnClick={() => updateCreatorState("client_declined")}
            />
          </ActionbarContainer>
        </Footer>
      </FooterWrapper>
    </Root>
  );
};

const Root = styled.div`
  margin-left: 24px;
  height: calc(100vh - 240px);
  min-width: 380px;
  max-width: 380px;
  border: 1px solid ${palette.gray[20]};
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: sticky;
  top: 8px;
`;

const HeaderWrapper = styled.div`
  border-bottom: 1px solid ${palette.gray[20]};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

const UserInfoContainer = styled.div`
  display: flex;

  > .wrapper {
    display: flex;
    flex-direction: column;

    .platform-name {
      display: inline-flex;
      max-width: 140px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .platform-icon {
      color: ${palette.gray[60]};
      padding-left: 4px;
    }

    > .stats {
      color: ${palette.gray[60]};
    }
  }
  > .avatar {
    border-radius: 50%;
    margin-right: 8px;
  }
`;

const SidebarIcon = styled(FontAwesomeIcon)`
  && {
    color: ${palette.gray[60]};
    margin-left: 16px;
    width: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
      color: ${palette.gray[80]};
    }

    &.disabled {
      color: ${palette.gray[20]};
    }

    &:last-child {
      width: 20px;
      height: 20px;
      position: relative;
      top: 1.5px;
    }
  }
`;

const InfoContainer = styled.div`
  padding: 0px 24px;
  overflow: auto;
  height: auto;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border: 1px solid ${palette.gray[20]};
  border-radius: 8px;
`;

const ClientDataRow = styled.div`
  padding: 8px 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 40% 57.5%;
  grid-gap: 2.5%;
  border-top: 1px solid ${palette.gray[20]};

  > .label {
    word-break: break-word;
  }

  > .data {
    text-align: right;
    padding-left: 8px;
    justify-self: end;
    word-break: break-word;

    > a {
      color: ${palette.gray.black};
      font-weight: bold;
    }
  }
`;

const FooterWrapper = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${palette.gray[20]};
`;

const Footer = styled.div`
  padding: 16px 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionbarContainer = styled.div<{ $showActionButtons: boolean }>`
  width: 100%;
  padding: 12px 0 16px;
  display: flex;
  justify-content: ${p => (p.$showActionButtons ? "space-between" : "center")};
  align-items: center;

  > .state-change-fab {
    display: ${p => (p.$showActionButtons ? "inherit" : "none")};

    &.approve {
      background-color: ${palette.gray.black};
      color: ${palette.gray.white};
    }
  }
`;
