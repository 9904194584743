import { managerApproved } from "@relatable/helpers/approvalLogs";
import type { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { Loader } from "@relatable/ui/Loader";
import { useSnackbar } from "@relatable/ui/Snackbar";

import { useUser } from "hooks/useUser";

import { ContentApproval } from "./ContentApproval";
import { getContentState } from "./common/helpers";
import {
  CampaignsOverviewDocument,
  ContentDetailsDocument,
  useAddCommentMutation,
  useCampaignsOverviewQuery,
  useSendNotificationMutation,
  useUpdateStateMutation
} from "./generated";

export const ContentApprovalContainer: React.FunctionComponent<{
  campaignId: number;
}> = ({ campaignId }) => {
  const {
    data: {
      campaigns: [campaign] = []
    } = {}
  } = useCampaignsOverviewQuery({
    variables: { campaignId }
  });
  const user = useUser();
  const snackbar = useSnackbar();

  const [sendNotification] = useSendNotificationMutation();
  const [updateState, { loading: updateStateLoading }] = useUpdateStateMutation({
    refetchQueries: [CampaignsOverviewDocument, ContentDetailsDocument],
    awaitRefetchQueries: true
  });
  const [addComment, { loading: addCommentLoading }] = useAddCommentMutation({
    refetchQueries: [CampaignsOverviewDocument, ContentDetailsDocument],
    awaitRefetchQueries: true
  });

  const onUpdateState = (
    id: number,
    contentType: "media" | "caption",
    newState: CONTENT_APPROVAL_STATE
  ) => {
    const idField = (() => {
      if (contentType === "media") return { mediaId: id };
      if (contentType === "caption") return { captionId: id };
      return { contentId: id };
    })();

    return updateState({
      variables: {
        state: newState,
        ...(user?.admin ? { accountId: user?.admin.id } : { clientContactId: user?.client?.id }),
        ...idField
      }
    });
  };

  const onAddComment = (id: number, contentType: "media" | "caption", text: string) => {
    return addComment({
      variables: {
        text,
        ...(user?.admin ? { accountId: user?.admin.id } : { clientContactId: user?.client?.id }),
        ...(contentType === "media" ? { mediaId: id } : { captionId: id })
      }
    });
  };

  const onSubmitReview = async () => {
    await sendNotification({
      variables: {
        campaignId,
        text: "Client submitted new review"
      }
    });

    snackbar.success("Your review was successfully submitted. Your PM will be in contact.");
  };

  if (!campaign) return <Loader />;

  const filteredUsers = campaign.campaign_users.map(cu => ({
    ...cu,
    campaign_preapproval_contents: cu.campaign_preapproval_contents.filter(cpc =>
      managerApproved(getContentState(cpc).state)
    )
  }));

  return (
    <ContentApproval
      pmEmail={campaign.account?.email ?? null}
      campaignUsers={filteredUsers}
      platform={campaign.platform}
      onUpdateState={onUpdateState}
      updateStateLoading={updateStateLoading}
      onAddComment={onAddComment}
      addCommentLoading={addCommentLoading}
      onSubmitReview={onSubmitReview}
    />
  );
};
